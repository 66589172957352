import * as React from 'react';


const Waves = [
  'M0,126L40,105C80,84,160,42,240,25.7C320,9,400,19,480,25.7C560,33,640,37,720,35C800,33,880,23,960,18.7C1040,14,1120,14,1200,11.7C1280,9,1360,5,1440,4.7C1520,5,1600,9,1680,18.7C1760,28,1840,42,1920,53.7C2000,65,2080,75,2160,65.3C2240,56,2320,28,2400,16.3C2480,5,2560,9,2640,30.3C2720,51,2800,89,2880,86.3C2960,84,3040,42,3120,42C3200,42,3280,84,3360,88.7C3440,93,3520,61,3600,56C3680,51,3760,75,3840,86.3C3920,98,4000,98,4080,100.3C4160,103,4240,107,4320,93.3C4400,79,4480,47,4560,39.7C4640,33,4720,51,4800,51.3C4880,51,4960,33,5040,37.3C5120,42,5200,70,5280,67.7C5360,65,5440,33,5520,30.3C5600,28,5680,56,5720,70L5760,84L5760,140L5720,140C5680,140,5600,140,5520,140C5440,140,5360,140,5280,140C5200,140,5120,140,5040,140C4960,140,4880,140,4800,140C4720,140,4640,140,4560,140C4480,140,4400,140,4320,140C4240,140,4160,140,4080,140C4000,140,3920,140,3840,140C3760,140,3680,140,3600,140C3520,140,3440,140,3360,140C3280,140,3200,140,3120,140C3040,140,2960,140,2880,140C2800,140,2720,140,2640,140C2560,140,2480,140,2400,140C2320,140,2240,140,2160,140C2080,140,2000,140,1920,140C1840,140,1760,140,1680,140C1600,140,1520,140,1440,140C1360,140,1280,140,1200,140C1120,140,1040,140,960,140C880,140,800,140,720,140C640,140,560,140,480,140C400,140,320,140,240,140C160,140,80,140,40,140L0,140Z',
  'M0,126L40,114.3C80,103,160,79,240,58.3C320,37,400,19,480,14C560,9,640,19,720,35C800,51,880,75,960,81.7C1040,89,1120,79,1200,63C1280,47,1360,23,1440,18.7C1520,14,1600,28,1680,32.7C1760,37,1840,33,1920,28C2000,23,2080,19,2160,28C2240,37,2320,61,2400,67.7C2480,75,2560,65,2640,51.3C2720,37,2800,19,2880,23.3C2960,28,3040,56,3120,63C3200,70,3280,56,3360,42C3440,28,3520,14,3600,18.7C3680,23,3760,47,3840,65.3C3920,84,4000,98,4080,95.7C4160,93,4240,75,4320,65.3C4400,56,4480,56,4560,67.7C4640,79,4720,103,4800,95.7C4880,89,4960,51,5040,49C5120,47,5200,79,5280,86.3C5360,93,5440,75,5520,74.7C5600,75,5680,93,5720,102.7L5760,112L5760,140L5720,140C5680,140,5600,140,5520,140C5440,140,5360,140,5280,140C5200,140,5120,140,5040,140C4960,140,4880,140,4800,140C4720,140,4640,140,4560,140C4480,140,4400,140,4320,140C4240,140,4160,140,4080,140C4000,140,3920,140,3840,140C3760,140,3680,140,3600,140C3520,140,3440,140,3360,140C3280,140,3200,140,3120,140C3040,140,2960,140,2880,140C2800,140,2720,140,2640,140C2560,140,2480,140,2400,140C2320,140,2240,140,2160,140C2080,140,2000,140,1920,140C1840,140,1760,140,1680,140C1600,140,1520,140,1440,140C1360,140,1280,140,1200,140C1120,140,1040,140,960,140C880,140,800,140,720,140C640,140,560,140,480,140C400,140,320,140,240,140C160,140,80,140,40,140L0,140Z',
  'M0,70L40,74.7C80,79,160,89,240,77C320,65,400,33,480,35C560,37,640,75,720,79.3C800,84,880,56,960,39.7C1040,23,1120,19,1200,16.3C1280,14,1360,14,1440,14C1520,14,1600,14,1680,16.3C1760,19,1840,23,1920,25.7C2000,28,2080,28,2160,25.7C2240,23,2320,19,2400,35C2480,51,2560,89,2640,93.3C2720,98,2800,70,2880,60.7C2960,51,3040,61,3120,70C3200,79,3280,89,3360,77C3440,65,3520,33,3600,16.3C3680,0,3760,0,3840,18.7C3920,37,4000,75,4080,93.3C4160,112,4240,112,4320,114.3C4400,117,4480,121,4560,116.7C4640,112,4720,98,4800,93.3C4880,89,4960,93,5040,84C5120,75,5200,51,5280,53.7C5360,56,5440,84,5520,84C5600,84,5680,56,5720,42L5760,28L5760,140L5720,140C5680,140,5600,140,5520,140C5440,140,5360,140,5280,140C5200,140,5120,140,5040,140C4960,140,4880,140,4800,140C4720,140,4640,140,4560,140C4480,140,4400,140,4320,140C4240,140,4160,140,4080,140C4000,140,3920,140,3840,140C3760,140,3680,140,3600,140C3520,140,3440,140,3360,140C3280,140,3200,140,3120,140C3040,140,2960,140,2880,140C2800,140,2720,140,2640,140C2560,140,2480,140,2400,140C2320,140,2240,140,2160,140C2080,140,2000,140,1920,140C1840,140,1760,140,1680,140C1600,140,1520,140,1440,140C1360,140,1280,140,1200,140C1120,140,1040,140,960,140C880,140,800,140,720,140C640,140,560,140,480,140C400,140,320,140,240,140C160,140,80,140,40,140L0,140Z',
  'M0,70L40,70C80,70,160,70,240,77C320,84,400,98,480,98C560,98,640,84,720,81.7C800,79,880,89,960,84C1040,79,1120,61,1200,56C1280,51,1360,61,1440,67.7C1520,75,1600,79,1680,88.7C1760,98,1840,112,1920,109.7C2000,107,2080,89,2160,72.3C2240,56,2320,42,2400,51.3C2480,61,2560,93,2640,105C2720,117,2800,107,2880,105C2960,103,3040,107,3120,102.7C3200,98,3280,84,3360,70C3440,56,3520,42,3600,32.7C3680,23,3760,19,3840,28C3920,37,4000,61,4080,67.7C4160,75,4240,65,4320,63C4400,61,4480,65,4560,77C4640,89,4720,107,4800,114.3C4880,121,4960,117,5040,116.7C5120,117,5200,121,5280,114.3C5360,107,5440,89,5520,74.7C5600,61,5680,51,5720,46.7L5760,42L5760,140L5720,140C5680,140,5600,140,5520,140C5440,140,5360,140,5280,140C5200,140,5120,140,5040,140C4960,140,4880,140,4800,140C4720,140,4640,140,4560,140C4480,140,4400,140,4320,140C4240,140,4160,140,4080,140C4000,140,3920,140,3840,140C3760,140,3680,140,3600,140C3520,140,3440,140,3360,140C3280,140,3200,140,3120,140C3040,140,2960,140,2880,140C2800,140,2720,140,2640,140C2560,140,2480,140,2400,140C2320,140,2240,140,2160,140C2080,140,2000,140,1920,140C1840,140,1760,140,1680,140C1600,140,1520,140,1440,140C1360,140,1280,140,1200,140C1120,140,1040,140,960,140C880,140,800,140,720,140C640,140,560,140,480,140C400,140,320,140,240,140C160,140,80,140,40,140L0,140Z',
  'M0,126L40,123.7C80,121,160,117,240,95.7C320,75,400,37,480,32.7C560,28,640,56,720,60.7C800,65,880,47,960,53.7C1040,61,1120,93,1200,105C1280,117,1360,107,1440,98C1520,89,1600,79,1680,77C1760,75,1840,79,1920,72.3C2000,65,2080,47,2160,44.3C2240,42,2320,56,2400,72.3C2480,89,2560,107,2640,105C2720,103,2800,79,2880,72.3C2960,65,3040,75,3120,86.3C3200,98,3280,112,3360,116.7C3440,121,3520,117,3600,95.7C3680,75,3760,37,3840,28C3920,19,4000,37,4080,49C4160,61,4240,65,4320,56C4400,47,4480,23,4560,14C4640,5,4720,9,4800,25.7C4880,42,4960,70,5040,77C5120,84,5200,70,5280,58.3C5360,47,5440,37,5520,30.3C5600,23,5680,19,5720,16.3L5760,14L5760,140L5720,140C5680,140,5600,140,5520,140C5440,140,5360,140,5280,140C5200,140,5120,140,5040,140C4960,140,4880,140,4800,140C4720,140,4640,140,4560,140C4480,140,4400,140,4320,140C4240,140,4160,140,4080,140C4000,140,3920,140,3840,140C3760,140,3680,140,3600,140C3520,140,3440,140,3360,140C3280,140,3200,140,3120,140C3040,140,2960,140,2880,140C2800,140,2720,140,2640,140C2560,140,2480,140,2400,140C2320,140,2240,140,2160,140C2080,140,2000,140,1920,140C1840,140,1760,140,1680,140C1600,140,1520,140,1440,140C1360,140,1280,140,1200,140C1120,140,1040,140,960,140C880,140,800,140,720,140C640,140,560,140,480,140C400,140,320,140,240,140C160,140,80,140,40,140L0,140Z'
];

export const Wave = ({waveNumber}) => (
  <div className="wave-container">
    <svg className="wave"
         viewBox="0 0 1440 140">
      <path style={{fill: 'currentcolor'}}
            d={Waves[waveNumber]}/>
    </svg>
  </div>
);